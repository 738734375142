import firebase from "firebase/app";
import "firebase/firestore";

require('firebase/auth')

const firebaseConfig = {
    apiKey: "AIzaSyA7tiPl0ZDpObfWw1i0JnO8rT7gSckMcWk",
    authDomain: "pfdaa-7f092.firebaseapp.com",
    databaseURL: "https://pfdaa-7f092.firebaseio.com",
    projectId: "pfdaa-7f092",
    storageBucket: "pfdaa-7f092.appspot.com",
    messagingSenderId: "893862466268",
    appId: "1:893862466268:web:35813321cc9b6bedd51a19",
    measurementId: "G-HQEYVJ65DF"
};
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
// export const _storage = getStorage(firebaseApp)