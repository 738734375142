<template>
  <div class="event-card">
     <div class="card">
        <div class="card-image">
          <figure class="image is-4by3">
            <img :src="event.featuredImage" alt="Placeholder image" />
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">{{ event.name }}</p>
              <p class="subtitle is-6">{{ event.date }}</p>
              <p class="subtitle is-6">{{ event.location }}</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["event"]
};
</script>
<style lang="scss" scoped>
</style>