<template>
  <div class="ensemble-card">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="ensemble.featuredImage" alt="Placeholder image" />
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ ensemble.name }}</p>
            <p class="subtitle is-6">{{ ensemble.city }}, {{ensemble.state}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["ensemble"]
};
</script>
<style lang="scss" scoped>
</style>