<template>
  <nav class="navbar container" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item logo" href="/">
        <!-- <strong class="is-size-5">Polish Folk Dance Association of the Americas</strong> -->
        <img src="https://pfdaa-app-uploads-public.s3.amazonaws.com/pfdaa_logo.png"/>
      </a>
      <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          @click="isOpen = !isOpen"
          v-bind:class="{'is-active': isOpen}"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar" class="navbar-menu" v-bind:class="{'is-active': isOpen}">
      <div class="navbar-start">
        <router-link to="/" class="navbar-item">Home</router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">About</a>


          <div class="navbar-dropdown">
            <a class="navbar-item">
              <router-link to="/about" class="navbar-item">About Us</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/board" class="navbar-item">Our Board</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/join" class="navbar-item">Join the PFDAA</router-link>
            </a>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Resources</a>

          <div class="navbar-dropdown">
            <!-- <a class="navbar-item">
              <router-link to="/underConstruction" class="navbar-item">Music</router-link>
            </a>-->
            <a class="navbar-item">
              <router-link to="/books" class="navbar-item">Books</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/costumes" class="navbar-item">Costumes</router-link>
            </a>
            <!-- <hr class="navbar-divider" /> -->

          </div>
        </div>
        <!--        <div class="navbar-item has-dropdown is-hoverable" v-if="$auth.isAuthenticated && $auth.userInfo.currentWithDues">-->
        <div class="navbar-item has-dropdown is-hoverable" v-if="store.currentWithDues">
          <a class="navbar-link">Member Resources</a>

          <div class="navbar-dropdown">
            <a class="navbar-item">
              <router-link to="/marketplace" class="navbar-item">Marketplace</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/music" class="navbar-item">Music</router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/videos" class="navbar-item">Videos</router-link>
            </a>
          </div>
        </div>
        <div class="navbar-item is-hoverable" v-else-if="store.isLoggedIn">
          <a class="navbar-item">
            <router-link to="/moreInfoNeeded" class="navbar-item">Member Resources</router-link>
          </a>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <!-- Check that the SDK client is not currently loading before accessing is methods -->
            <!-- <a class="button is-light"> -->
            <form
                action="https://www.paypal.com/cgi-bin/webscr"
                target="_blank"
                method="post"
                style="float:center"
            >
              <input type="hidden" name="cmd" value="_s-xclick"/>
              <input type="hidden" name="hosted_button_id" value="PRKGKRJB4CGGG"/>
              <button class="button is-dark" name="submit">Donate</button>
            </form>
            <br/>
            <div v-if="store.isLoggedIn">
              <li class="nav-item">
                <router-link to="/">
                  <button class="button is-dark" name="submit" @click="logout">Log out</button>
                </router-link>
              </li>
            </div>
            <div v-else>
              <li class="nav-item">
                <router-link to="/login">
                  <button class="button is-dark" name="submit">Login</button>
                </router-link>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import { useUserStore } from "../../store"
export default {
  name: "Nav",
  setup(){
    let store = useUserStore()
    return {store}
  },
  computed: {
    isLoggedIn() {
      return this.store.isLoggedIn;
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    logout() {
      this.store.logout();
      // this.isLoggedIn = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.navbar-brand {
  align-items: center;
  justify-content: center;

  .navbar-item img {
    max-height: 200px;
  }
}

nav {
  margin-top: 0px;
  margin-bottom: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #d88d00;
    }
  }
}

button {
  margin-left: 5px;
  margin-right: 5px;
}
</style>