<template>
  <div class="home">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Welcome to the Polish Folk Dance Association of the Americas</h1>
        </div>
      </div>
    </section>
    <EventsList />
    <Newsletter />
    <Ensembles />
  </div>
</template>
<script>
import EventsList from "../components/EventsList";
import Newsletter from "../components/Newsletter";
import Ensembles from "../components/Ensembles";

export default {
  name: "home",
  components: {
    EventsList,
    Newsletter,
    Ensembles
  },
  methods: {
    login() {
      // this.$auth.loginWithRedirect();
    }
  }
};

</script>
<style lang="scss" scoped>
.hero {
  text-align: center;
  background-image: url("https://pfdaa-app-uploads-public.s3.amazonaws.com/polishflags.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;

  img {
    height: 300px;
    width: auto;
  }
}
.hero-body .title {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  padding: 40px 0 20px 0;
  font-size: 60px;
}
.subtitle {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  font-size: 30px;
}
.button-block {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: absolute;
  bottom: -150px;
  .button {
    margin-right: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .welcome {
    width: 400px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
.is-xl {
  font-size: 1.7rem;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>