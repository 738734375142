import Home from "../views/Home";
// import {useUserStore} from "../store";
// let store = useUserStore()

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    },
    {
        path: '/board',
        name: 'board',
        component: () => import('../views/Board.vue')
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('../views/Join.vue')
    },
    {
        path: '/costumes',
        name: 'costumes',
        component: () => import('../views/Costumes.vue')
    },
    {
        path: '/books',
        name: 'books',
        component: () => import('../views/Books.vue')
    },
    {
        path: '/underConstruction',
        name: 'notready',
        component: () => import('../views/UnderConstruction.vue')
    },
    {
        path: '/event/:id',
        name: 'eventSingle',
        component: () => import('../views/EventSingle.vue'),
    },
    {
        path: '/ensemble/:id',
        name: 'ensembleSingle',
        component: () => import('../views/EnsembleSingle.vue'),
    },
    {
        path: '/memberResources',
        name: 'memberResources',
        component: () => import('../views/MemberResources.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/marketplace',
        name: 'marketplace',
        component: () => import('../views/Marketplace.vue'),
        // meta: {requiresAuth: true}
    },
    {
        path: '/music',
        name: 'music',
        component: () => import('../views/Music.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/videos',
        name: 'videos',
        component: () => import('../views/Videos.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/moreInfoNeeded',
        name: 'Member Resources',
        component: () => import('../views/MoreInfoNeeded.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../views/Login")
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import("../views/Register")
    },
    {
        path: '/addMarketplaceItem',
        name: 'Add Marketplace Item',
        component: () => import("../views/AddMarketplaceItem"),
        // meta: {requiresAuth: true}
    },
    {
        path: '/marketplaceItem/:id',
        name: 'Marketplace Item',
        component: () => import("../views/MarketplaceItem"),
        // meta: {requiresAuth: true}
    },
]

export default routes;