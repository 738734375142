<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Nav from './components/partials/Nav.vue';
import Footer from './components/partials/Footer';
export default {
  name: 'app',
  components: {
    Nav,
    Footer
  }  
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Cambay|Noto+Sans&display=swap');

#app {
  font-family: 'Cambay', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>