<template>
  <div class="ensembles container">
    <h2 class="subtitle is-3">Our Dance Groups</h2>
    <div class="columns is-multiline">
      <div
        v-for="ensemble in ensembles"
        :ensemble="ensemble"
        :key="ensemble.id"
        class="column is-one-quarter"
      >
        <router-link :to="`/ensemble/${ensemble.id}`">
          <EnsembleCard :ensemble="ensemble" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import EnsembleCard from "@/components/EnsembleCard";
import EnsembleService from "../services/ensembleService";

export default {
  name: "Ensembles",
  components: {
    EnsembleCard
  },
  data() {
    return {
      ensemble: {},
      ensembles: []
    };
  },
  async mounted() {
    this.ensembles = await EnsembleService.getAll()
  }
};
</script>
<style lang="scss" scoped>
.ensembles {
  margin-top: 100px;
  text-align: center;
}
</style>