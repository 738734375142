import routes from "./router/routes";

import VueGoogleMaps from '@fawmi/vue-google-maps'
import {createApp} from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './../node_modules/bulma/css/bulma.css';
import {createRouter, createWebHistory} from 'vue-router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) =>{
  if(to.meta.requiresAuth && localStorage.getItem("isAuth") !== "true"){
    return {
      path: '/login',
      query: {redirect: to.fullPath}
    }
  }
})

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAyQ4Q-vYM8JKQz0pO8JN8foESjYhF9gLk',
  },
})
window.onbeforeunload = () => {
  localStorage.removeItem('isAuth');
}
const pinia = createPinia()
app.use(pinia)
app.use(VueSweetalert2);
app.use(router)
app.mount('#app')
