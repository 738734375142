import { defineStore } from 'pinia'
import UserService from "./services/userService";

export const useUserStore = defineStore('users', {
    state: () => {
        return {
            isLoggedIn: false,
            email:"",
            currentWithDues:false,
            group:"",
            isBoardMember:false,
            userId:"",
        }
    },
    actions:{
        async login(email) {
            this.isLoggedIn = true;
            this.email = email;
            let userinfo = await UserService.getByEmail(email);
            if (userinfo){
                this.currentWithDues = userinfo.currentWithDues;
                this.group = userinfo.group;
                this.isBoardMember = userinfo.isBoardMember;
                this.userId = userinfo.id;
            }
            localStorage.setItem('isAuth', "true")
        },
        logout(){
            this.isLoggedIn = false;
            this.email = "";
            this.currentWithDues = false;
            this.group = "";
            this.isBoardMember = false;
            this.userId = "";
            localStorage.removeItem('isAuth')
        }
    }
})