<template>
  <div class="events container" v-if="this.events.length">
    <h2 class="subtitle is-3" >Upcoming Events</h2>
    <div class="columns is-multiline">
      <div v-for="event in events" :event="event" :key="event.id" class="column is-one-quarter">
        <router-link :to="`/event/${event.id}`">
          <EventCard :event="event" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import EventCard from "@/components/EventCard";
import EventService from "../services/eventService"

export default {
  name: "EventsList",
  components: {
    EventCard
  },
  data() {
    return {
      event: {},
      events: []
    };
  },
  async mounted() {
    let events = await EventService.getAll();
    this.events = events.filter(_event => _event.visible === true);
  }
};
</script>
<style lang="scss" scoped>
.events {
  margin-top: 100px;
  text-align: center;
}
</style>